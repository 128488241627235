// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

// apollo
import { ApolloClient, ApolloProvider, ApolloLink, createHttpLink } from '@apollo/client';

import { InMemoryCache } from '@apollo/client/cache';
import { Amplify } from 'aws-amplify';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';

import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

// redux
import { store, persistor } from './redux/store';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

// import { AuthProvider } from './contexts/JWTContext';
// import { AuthProvider } from './contexts/Auth0Context';
// import { AuthProvider } from './contexts/FirebaseContext';
import { AuthProvider } from './contexts/AwsCognitoContext';

//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { MGROUPT_API, AWS_MOBILE } from './config';

Amplify.configure(AWS_MOBILE);

// eslint-disable-next-line
const url = AWS_MOBILE.aws_appsync_graphqlEndpoint;
// eslint-disable-next-line
const region = AWS_MOBILE.aws_appsync_region;

const authUserPool = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: localStorage.getItem('jwtToken'),
};

const authApiKey = {
  type: 'API_KEY',
  apiKey: AWS_MOBILE.aws_appsync_apiKey,
};

let auth = authApiKey;

if (MGROUPT_API.typeAuthMgt === 'COGNITO_USER_POOLS') {
  auth = authUserPool;
}

// console.log(localStorage.getItem('jwtToken'));
// console.log(auth);
// console.log(url);
// console.log(AWS_MOBILE);

const httpLink = createHttpLink({ uri: url });

const link = ApolloLink.from([createAuthLink({ url, region, auth }), createSubscriptionHandshakeLink(url, httpLink)]);

const clientApollo = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthProvider>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <CollapseDrawerProvider>
                <BrowserRouter>
                  <ApolloProvider client={clientApollo}>
                    <App />
                  </ApolloProvider>
                </BrowserRouter>
              </CollapseDrawerProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  </AuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
